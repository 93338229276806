import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Call from '../components/Call';

const Services = ({ data, location }) => {
  const serviceItems = data.serviceItems.edges;
  const { html, frontmatter } = data.services;

  return (
    <Layout bodyClass="page-services">
      <SEO
        title="Dienste"
        description={frontmatter.description}
        image={frontmatter.services_image_meta}
        url={location.href}
      />

      <div className="services pt-6">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>{frontmatter.title}</h1>
            </div>
          </div>
          <br />
          <div className="row">
            {frontmatter.services_image && (
              <div className="col-12">
                <img
                  alt={frontmatter.title}
                  className="img-fluid"
                  src={frontmatter.services_image}
                />
              </div>
            )}
          </div>
          <br />
          <div className="row">
            <div className="col-12">
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {serviceItems.map((edge) => (
            <div key={edge.node.id} className="col-12 col-md-4 mb-1">
              <div className="card service service-teaser">
                <div className="card-content">
                  <h2>
                    <Link to={edge.node.fields.slug}>
                      {edge.node.frontmatter.title}
                    </Link>
                  </h2>
                  <p>
                    {edge.node.excerpt}
                    {'. '}
                    <Link to={edge.node.fields.slug}>Mehr</Link>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container pt-6 pb-6">
        <Call showButton />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ServicesQuery {
    serviceItems: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/services/.*/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    services: markdownRemark(fileAbsolutePath: { regex: "/(services.md)/" }) {
      html
      frontmatter {
        title
        description
        image
        services_image
        services_image_absolute
        services_image_hide_on_mobile
        services_image_meta
      }
      fields {
        slug
      }
    }
  }
`;

export default Services;
